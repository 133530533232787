"use strict";
import * as model from "./model.js";
import View from "./views/View.js";

const sectionDetails = document.querySelector(".section-details");

View.generateTitleContainer(sectionDetails);

const { btnCurrentLocation, searchField, detailsContainer } =
  View.initialMarkup(sectionDetails);
// Google Autocomplete
model.getGoogleAutoCompleteApi(searchField, detailsContainer, sectionDetails);

// Get Current Location
View.addClickListenerToButton(btnCurrentLocation, async function () {
  try {
    View.goBackBtn(sectionDetails);
    View.renderSpinner(detailsContainer);
    await model.getCoords();
    await View.render(detailsContainer, model);
  } catch (err) {
    View.showError(detailsContainer);
  }
});
