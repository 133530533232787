"use strict";
import { AJAX } from "./helper.js";
import View from "./views/View.js";
import { LOCATION_API_KEY, LOCATION_API_URL } from "./config.js";

// Icons
import clear from "url:../icons/clear.svg";
import cloud from "url:../icons/cloud.svg";
import haze from "url:../icons/haze.svg";
import rain from "url:../icons/rain.svg";
import snow from "url:../icons/snow.svg";
import storm from "url:../icons/storm.svg";

// State
export const state = {
  city: {
    name: null,
    temp: null,
    weather: null,
    humidity: null,
    windSpeed: null,
    feelsLike: null,
    icon: null,
  },
  coords: {
    lat: null,
    long: null,
  },
};

// Get City Name
export const getCityName = async function () {
  try {
    const data = await AJAX(
      `${LOCATION_API_URL}latlng=${state.coords.lat},${state.coords.long}&key=${LOCATION_API_KEY}`
    );
    state.city.name = data.results[0].address_components[2].long_name;
    return state.city.name;
  } catch (err) {
    throw err;
  }
};

// Get Coords
export const getCoords = async function () {
  try {
    const pos = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    (state.coords.long = pos.coords.longitude),
      (state.coords.lat = pos.coords.latitude);

    return state.coords;
  } catch (err) {
    throw err;
  }
};

// Get Coords from Google Location API
export const getGoogleAutoCompleteApi = async function (
  searchField,
  detailsContainer,
  sectionDetails
) {
  try {
    let autocomplete;

    await (autocomplete = new google.maps.places.Autocomplete(searchField)),
      {
        types: ["geocode"],
      };

    await google.maps.event.addListener(
      autocomplete,
      "place_changed",
      async function () {
        let place = await autocomplete.getPlace();
        (state.coords.lat = place.geometry.location.lat()),
          (state.coords.long = place.geometry.location.lng());
        View.renderSpinner(detailsContainer);
        await View.render(detailsContainer);
        View.goBackBtn(sectionDetails);
        return state.coords;
      }
    );
  } catch (err) {
    throw err;
  }
};

// Get Weather
export const getWeatherAPI = async function (url, key, lat, long) {
  try {
    const data = await AJAX(`${url}?lat=${lat}&lon=${long}&appid=${key}`);
    state.city.temp = data.main.temp;
    state.city.weather = data.weather[0].main;
    state.city.humidity = data.main.humidity;
    state.city.windSpeed = data.wind.speed;
    state.city.feelsLike = data.main.feels_like;

    if (data.weather[0].id == 800) {
      state.city.icon = clear;
    } else if (data.weather[0].id >= 200 && data.weather[0].id <= 232) {
      state.city.icon = storm;
    } else if (data.weather[0].id >= 600 && data.weather[0].id <= 622) {
      state.city.icon = snow;
    } else if (data.weather[0].id >= 701 && data.weather[0].id <= 781) {
      state.city.icon = haze;
    } else if (data.weather[0].id >= 801 && data.weather[0].id <= 804) {
      state.city.icon = cloud;
    } else if (
      (data.weather[0].id >= 500 && data.weather[0].id <= 531) ||
      (data.weather[0].id >= 300 && data.weather[0].id <= 321)
    ) {
      state.city.icon = rain;
    }

    return data;
  } catch (err) {
    throw err;
  }
};
