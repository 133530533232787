"use strict";
import * as model from "../model.js";
import { API_URL, API_KEY } from "../config.js";

class View {
  async render(section) {
    await this._showData(section);
    section.innerHTML = this._generateMarkup();
  }

  async _showData(section) {
    await model.getCityName();

    const data = await model.getWeatherAPI(
      API_URL,
      API_KEY,
      model.state.coords.lat,
      model.state.coords.long
    );
  }

  generateTitleContainer(section) {
    const markup = `<div class="title-container">
    <h1 class="title">Climater</h1>
  </div>`;
    section.insertAdjacentHTML("afterbegin", markup);
  }

  initialMarkup(section) {
    const html = `
  <div class="details-container">
    <input
      type="text"
      class="input-search"
      placeholder="Enter City Name"
    />
    <div class="separator" bis_skin_checked="1"></div>
    <button class="btn-currentLocation">
      <i class="bx bx-current-location"></i>
      Get Current Location
    </button>
  </div>`;

    section.insertAdjacentHTML("beforeend", html);

    const detailsContainer = document.querySelector(".details-container");
    const btnCurrentLocation = document.querySelector(".btn-currentLocation");
    const searchField = document.querySelector(".input-search");
    return { btnCurrentLocation, searchField, detailsContainer };
  }

  addClickListenerToButton(btn, callback) {
    btn.addEventListener("click", callback);
  }

  goBackBtn(section) {
    section.insertAdjacentHTML(
      "afterend",
      `<button class="goBack-btn">Go Back</button>`
    );
    document
      .querySelector(".goBack-btn")
      .addEventListener("click", function () {
        location.reload();
      });
  }

  showError(
    section,
    err = ` 
    Something went wrong! Please try again`
  ) {
    const html = `
    <div class="details-container">
    <h2 class="error">${err}</h2>
    </div>`;
    section.innerHTML = html;
  }

  _generateMarkup() {
    const markup = `
          <div class="container">
            <img src="${model.state.city.icon}" class="weather-icon" />
            <h2 class="weather-temperature">${Math.ceil(
              model.state.city.temp - 273.15
            )}°C</h2>
            <p class="weather-condition">${model.state.city.weather}</p>
            <h3 class="weather-city"><i class="bx bx-map-pin"></i> ${
              model.state.city.name
            }</h3>
          </div>
        </div>
        <div class="weather-details">
          <!-- Feels Like -->
          <div class="feels-like-container sub-container">
            <i class="bx bxs-thermometer weather-details-icon"></i>
            <div class="feels-like-details">
              <h2 class="feels-like weather-text">${Math.ceil(
                model.state.city.feelsLike - 273.15
              )}°C</h2>
              <p class="feels-like-text weather-text-detail">Feels Like</p>
            </div>
          </div>
          <!-- Humidity -->
          <div class="humidity-container sub-container">
            <i class="bx bx-droplet weather-details-icon"></i>
            <div class="humidity-details">
              <h2 class="humidity"></h2>
              <h2 class="feels-like weather-text">${
                model.state.city.humidity
              }%</h2>
              <p class="humidity-text weather-text-detail">humidity</p>
            </div>
            </div>
          </div>
          </div>
          `;

    return markup;
  }

  renderSpinner(section) {
    const html = `
    <div class="loading-spinner"></div>
`;
    section.innerHTML = html;
  }
}

export default new View();
